$(document).ready(function() {
  // Obtener los elementos de entrada
  var input1 = $('#q_auditor_team_user_first_name_or_auditor_team_user_last_name_cont');
  var checkBox = $('#q_auditor_team_user_first_name_or_auditor_team_user_last_name_null');

  // Cuando se ingresa texto en input1, desactivar input2
  input1.on('keyup', function() {
    if ($(this).val().length > 0) {
      checkBox.prop('disabled', true);
    } else {
      checkBox.prop('disabled', false);
    }
  });

  // Cuando se ingresa texto en input2, desactivar input1
  checkBox.on('change', function() {
    if ($(this).is(':checked')) {
      input1.prop('disabled', true);
    } else {
      input1.prop('disabled', false);
    }
  });
});
