// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require.context("images", true);

var jQuery = require("jquery");
import "../stylesheets/application.scss"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "@fortawesome/fontawesome-free/css/all"
import '@fortawesome/fontawesome-free/css/all.css'

// import 'select2'
import "chartkick/chart.js"
// import 'select2/dist/css/select2.css'
// import "select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css";

import moment from 'moment'
global.moment = moment;
window.moment = moment;
import 'src/bootstrap-datetimepicker.min.js'
import 'src/bootstrap-datetimepicker.min.css'

import bootbox from 'bootbox'
global.bootbox = bootbox;
window.bootbox = bootbox;

import 'src/jquery.passwordRequirements.css'
import 'src/jquery.passwordRequirements.min'

import 'src/progress.css'
import 'src/progress-bar.js'

import 'src/custom-search.js'

Turbolinks.start();
ActiveStorage.start();

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

/* trigger when page is ready */
$(document).ready(function () {
  $(".pr-password").passwordRequirements({});
  $('[data-bs-toggle="tooltip"]').tooltip();
  $('[data-toggle="tooltip"]').tooltip();
  // $('.select2-enable').select2({ width: '100%'});
});


$( document ).on('turbolinks:load', function() {
  // $('.select2-enable').select2({ width: '100%'});
});

Rails.start();
